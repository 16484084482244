<template>
  <v-form ref="formElem">
    <translation-locales />

    <v-card>
      <v-card-text>
        <v-select
          v-model="form.gateway_sku_id"
          label="SKU (associated from Core)"
          item-text="title"
          item-value="value"
          outlined
          :items="skuOptions"
          :rules="[required(form.gateway_sku_id)]"
          @input="skuChanged"
        ></v-select>

        <p>Subscription type: <b>{{ form.type }}</b> (controlled by core SKU -> plan)</p>
        <p>
          <span class="mr-1">{{ form.interval_count }}</span>
          <span class="mr-1">{{ form.interval_unit }}</span>
          <span v-if="form.delay_days">(+{{ form.delay_days }} days)</span>
        </p>

        <v-row>
          <v-col col="6">
            <v-select
              v-model="form.owner"
              label="Owner type"
              item-text="title"
              item-value="value"
              outlined
              :items="ownerOptions"
            ></v-select>
          </v-col>
        </v-row>

        <v-select
          v-model="form.distributor_id"
          label="Distributor"
          item-text="title"
          item-value="value"
          outlined
          :items="distributorOptions"
          :rules="[between(form.distributor_id, 1, 999999999)]"
        ></v-select>

        <translatable-input
          v-model="form.title"
          label="Title"
          outlined
          class="mt-5"
        />

        <translatable-editor
          v-model="form.content"
          label="Content"
          outlined
          class="mt-5"
        />

        <v-text-field
          v-model="form.selling_price"
          label="Reference Selling Price"
          outlined
          class="mt-5"
          disabled
        />

        <v-text-field
          v-model="form.card_quota"
          label="Card Quota (controlled by Core)"
          outlined
          class="mt-5"
          disabled
        />

        <v-text-field
          v-model="form.sort"
          label="Display Order (asc)"
          outlined
          class="mt-5"
        />

        <v-switch
          v-model="form.trial"
          color="primary"
          :label="form.trial ? 'Is Trial of another pass (must provide coupon code). Hidden when user trial once' : 'Is Trial of other pass? (No)'"
        ></v-switch>

        <v-switch
          v-model="form.bulk"
          color="primary"
          :label="form.bulk ? 'Is Bulk purchase of another pass (Agent can bulk purchase at discounted prices)' : 'Is Bulk purchase? (No. Instead it is customer pass)'"
        ></v-switch>

        <div v-show="form.trial || form.bulk">
          <p>Since the pass is either bulk-purchase (agent) or trial enabled, you must provide a parent pass to tell the system what is the next pass after the first recursion (agent gift period over, trial ends)</p>
          <v-select
            v-model="form.parent_id"
            label="Parent Pass"
            item-text="title"
            item-value="value"
            outlined
            :rules="[form.trial || form.bulk ? between(form.parent_id, 1, 99999999) : null]"
            :items="passOptions"
          ></v-select>
        </div>

        <v-text-field
          v-model="form.coupon_code"
          label="Coupon Code (optional)"
          hint="This coupon code must exist & be valid: free_trial type for trial, or standard coupon, not expired, included to same SKUs."
          outlined
          class="mt-5"
        />

        <v-switch
          v-model="form.active"
          color="primary"
          :label="form.active ? 'Active' : 'Inactive'"
        ></v-switch>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableEditor, TranslatableInput, TranslationLocales } from '@/components'
import { useDistributor, useTranslatable } from '@/composables'
import productStore from '@/modules/product/store'
import { t } from '@/plugins/i18n'
import { between, required } from '@core/utils/validation'
import { computed, ref } from '@vue/composition-api'

export default {
  components: { TranslationLocales, TranslatableInput, TranslatableEditor },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, initValue) => ({ [name]: props.resource ? props.resource[name] : initValue })

    const initialForm = {
      ...field('type', null),
      ...field('owner', 'individual'),
      ...field('distributor_id', null),
      ...field('gateway_sku_id', null),
      ...field('parent_id', null),
      ...field('gateway_type', 'core'),
      ...field('title', useTranslatable('title')),
      ...field('content', useTranslatable('content')),
      ...field('coupon_code', null),
      ...field('selling_price', null),
      ...field('card_quota', null),
      ...field('active', true),
      ...field('trial', false),
      ...field('bulk', false),
      ...field('sort', 10),
      ...field('interval_count', null),
      ...field('interval_unit', null),
      ...field('delay_days', null),
    }

    const form = ref({ ...initialForm })
    const formElem = ref(null)

    const validate = () => {
      if (!formElem.value.validate()) return
      const data = { ...form.value }
      emit('submit', data)
    }

    const { distributorOptions } = useDistributor()

    const ownerOptions = [
      { title: 'Individual', value: 'individual' },
      { title: 'Family', value: 'family' },
    ]

    const skus = productStore.state.planProducts.map(p => p.skus).flat()
    const skuOptions = skus.map(sku => ({
      title: `${t(sku.name)} (${sku.sku_number})`,
      value: sku.uuid,
    }))

    const skuChanged = () => {
      const sku = skus.find(s => s.uuid === form.value.gateway_sku_id)
      if (sku) {
        const { plan } = sku
        form.value.selling_price = sku.reference_price.selling_price
        form.value.card_quota = plan.card_quota
        form.value.type = plan.type
        form.value.delay_days = plan.delay_days
        form.value.interval_count = plan.interval_count
        form.value.interval_unit = plan.interval_unit
      }
    }

    const { passes } = productStore.state
    const passOptions = computed(() => passes
      .filter(pass => pass.distributor_id === form.value.distributor_id)
      .map(pass => ({
        title: `#${pass.id} ${t(pass.title)} (${pass.distributor.nicename})`,
        value: pass.id,
      })))

    skuChanged()

    return {
      form,
      formElem,
      validate,
      required,
      between,

      ownerOptions,
      distributorOptions,
      skuOptions,
      skuChanged,

      passOptions,
    }
  },
}
</script>
